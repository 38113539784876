import React, {useState, useEffect, ChangeEvent} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {ActionMeta, default as ReactSelect, OnChangeValue} from 'react-select';

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CImg,
  CButton,
  CCollapse,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {getTags as getTagsAction} from '@actions/tags';

import {AddCurator, WSRDataTable, WSRModal} from '../../../../components';
import {
  getPosts as getPostsAction,
  searchPosts as searchPostsAction,
} from '@actions/posts';

type Props = {
  curId: number;
  setPostModal: Function;
  setSelectedPost: any;
  postModal: boolean;
};

type SelectedPostItem = {
  author: string;
  categoryIds: Array<number>;
  createdAt: string;
  date: string;
  description: string;
  dynamicLink: string;
  id: number;
  image: string;
  isHiddenNewsFeed: boolean;
  isHiddenProfile: boolean;
  lang: string;
  logo: string;
  note: string;
  publisher: string;
  title: string;
  type: string;
  updatedAt: string;
  url: string;
  user: {
    firstName: string;
    fullName: string;
    id: number;
    jobTitle: string;
    lastName: string;
    photo: string;
    postUsers: Array<any>;
    role: string;
    userName: string;
  };
  userId: number;
};

const postTypes = [
  {value: 'podcast', label: 'Podcast'},
  {value: 'video', label: 'Video'},
  {value: 'article', label: 'Article'},
];

export const AddPosts: React.FC<Props> = (props: Props) => {
  const {curId, setPostModal, setSelectedPost, postModal} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [checkedPosts, setCheckedPosts] = useState<any[]>([]);

  const [accordion, setAccordion] = useState(1);
  const [searchCategory, setSearchCategory] = useState('');
  const [searchTag, setSearchTag] = useState('');
  const [selecteds, setSelecteds] = useState<Record<number, Object>>({});
  const [addCuratorModal, setAddCuratorModal] = useState(false);
  const [curationId, setCurationId] = useState({curatorId: 0});
  const [curatorName, setCuratorName] = useState('');
  const [modal, setModal] = useState(false);
  const [offset, setOffset] = useState(2);
  const [searchFields, setSearchFields] = useState({
    userId: 0,
    categoryIds: '',
    tagIds: '',
    startDate: '',
    endDate: '',
    type: '',
  });

  const fields = [
    {
      key: 'id',
      label: 'Id',
      _style: {width: '4%'},
      sorter: false,
      filter: false,
    },
    {
      key: 'logo',
      label: 'Logo',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'title',
      label: 'Title',
      sorter: false,
      filter: false,
    },
    {
      key: 'type',
      label: 'Type',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'author',
      label: 'Author',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'date',
      label: 'Date',
      sorter: false,
      filter: false,
    },
    {
      key: 'user',
      label: 'User',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'select_row',
      label: '',
      _style: {width: '5%'},
      sorter: false,
      filter: false,
    },
  ];

  const scopedSlots = {
    title: (item: any) => (
      <td key={item.id} onClick={() => history.push(`/posts/${item.id}`)}>
        <div className="cut-text-modal">{item.title}</div>
        <div className="small text-muted">
          Published: {moment(item.date).format('DD/MM/YYYY')}
        </div>
      </td>
    ),
    date: (item: any) => (
      <td key={item.id}>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
    ),
    logo: (item: any) => (
      <td key={item.id}>
        <div className="c-avatar">
          <CImg src={item.logo} className="c-avatar-img" alt=" " />
        </div>
      </td>
    ),
    user: (item: any) => (
      <td key={item.id}>
        <div className="cut-text">{item.user.userName}</div>
      </td>
    ),
    select_row: (item: any, index: any) => (
      <CButton
        className="my-2 mfs-auto"
        block
        onClick={() => {
          //dispatch(addCurationToMoodAction.request(item.id));
          addPosts(item);
        }}
        color="success">
        Add
      </CButton>
    ),
  };

  const {postList, searchResults, tagList} = useSelector(
    (state) => ({
      postList: state.posts.postList,
      searchResults: state.posts.postSearchResult,
      tagList: state.tags.tagList,
    }),
    shallowEqual,
  );

  const pageChange = (newPage: number) => {
    if (
      searchFields.categoryIds !== '' ||
      searchFields.userId !== 0 ||
      searchFields.startDate !== '' ||
      searchFields.endDate !== '' ||
      searchFields.type !== ''
    ) {
      search(searchResults.page + 10, searchFields, curId, newPage);
    } else {
      if ((newPage - 1) * 10 >= postList.posts.length) {
        dispatch(getPostsAction.request(50, offset * 50));
        setOffset(offset + 1);
      }
    }

    setPage(newPage);
  };

  const handleChange = (event: SelectedPostItem) => {
    const selectedIds: Array<number> = checkedPosts.map(
      (checkedOne) => checkedOne.id,
    );
    if (!selectedIds.includes(event.id)) {
      setSelecteds((prevState) => ({...prevState, [event.id]: event}));
      setCheckedPosts([...checkedPosts, {id: event.id, value: true}]);
    } else {
      setSelecteds((prevState) => {
        const dummy = {...prevState};
        delete dummy[event.id];
        return dummy;
      });
      setCheckedPosts((prevState) =>
        prevState.filter((it) => it.id != event.id),
      );
    }
  };

  const handleAddCurator = () => {
    setAddCuratorModal(true);
  };

  const addPosts = (item: any) => {
    console.log('item', item);
    setSelectedPost(item);
    setPostModal(false);
  };

  const handleFilter = () => {
    setSearchCategory('');
    setSearchTag('');
    setSearchFields({
      userId: 0,
      categoryIds: '',
      tagIds: '',
      startDate: '',
      endDate: '',
      type: '',
    });
  };

  const onChangeTagFilter = (e: ChangeEvent<HTMLLIElement>) => {
    e.preventDefault();
    const tagIds = e.target.value.toString();
    setSearchTag(tagIds.toString());
    setSearchFields((prevState) => {
      search(0, {...prevState, tagIds: tagIds}, curId, 0);
      return {...prevState, tagIds};
    });
  };

  const onChangeStartDate = (e: ChangeEvent<HTMLLIElement>) => {
    const startDate = e.target.value.toString();
    setSearchFields((prevState) => {
      search(0, {...prevState, startDate}, curId, 0);
      return {...prevState, startDate};
    });
  };

  const onChangeEndDate = (e: ChangeEvent<HTMLLIElement>) => {
    const endDate = e.target.value.toString();
    setSearchFields((prevState) => {
      search(0, {...prevState, endDate}, curId, 0);
      return {...prevState, endDate};
    });
  };

  const onChangePostType = (postTypeList: OnChangeValue<any, any>) => {
    let type = '';
    let i = 0;
    postTypeList.map((a: any) => {
      i++;
      if (postTypeList.length == i) {
        type = type + `"${a.label}"`;
      } else {
        type = type + `"${a.label}", `;
      }
    });
    type = '[' + type + ']';
    if (type === '[]') {
      setPage(1);
    }
    setSearchFields((prevState) => {
      search(0, {...prevState, type}, curId, 0);
      return {...prevState, type};
    });
  };

  const search = (
    page: number,
    searchFields: {
      userId: number;
      categoryIds: any;
      tagIds: any;
      startDate: string;
      endDate: string;
      type: string;
    },
    forCurationListId: number,
    newPage: number,
  ) => {
    if (newPage === 0) {
      dispatch(
        searchPostsAction.request(50, 50, searchFields, forCurationListId),
      );
    } else if ((newPage - 1) * 10 >= searchResults.posts.length) {
      dispatch(
        searchPostsAction.request(
          offset * 50,
          50,
          searchFields,
          forCurationListId,
        ),
      );
      setOffset(offset + 1);
    }
  };

  useEffect(() => {
    setSearchFields((searchFields) => ({
      ...searchFields,
      userId: curationId.curatorId,
    }));
    setSearchFields((prevState) => {
      search(0, {...prevState, userId: curationId.curatorId}, curId, 0);
      return {...prevState, curationId};
    });
  }, [curationId]);

  useEffect(() => {
    dispatch(getTagsAction.request());
  }, []);

  const data =
    searchFields.categoryIds !== '' ||
    searchFields.userId !== 0 ||
    searchFields.startDate !== '' ||
    searchFields.endDate !== '' ||
    searchFields.type !== ''
      ? searchResults
      : postList;

  return (
    <>
      <CModal show={postModal} onClose={setPostModal} size="lg">
        <CModalHeader closeButton>
          <CModalTitle>All Posts</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CCard className="mb-0">
                <CCardHeader id="headingOne">
                  <CButton
                    style={{width: '100%'}}
                    className="text-left m-0 p-0"
                    onClick={() => setAccordion(accordion === 0 ? 1 : 0)}>
                    <h5 className="m-0 mr-1 p-0">
                      Filter
                      <CIcon className="ml-2" name="cil-magnifying-glass" />
                    </h5>
                  </CButton>
                </CCardHeader>
                <CCollapse show={accordion === 0}>
                  <CCardBody>
                    <CRow>
                      <CCol xs="3">
                        <CFormGroup>
                          <CLabel>Tag</CLabel>
                          <CSelect
                            name="catid"
                            id="selectCat"
                            value={searchTag}
                            onChange={onChangeTagFilter}>
                            <option value={''}>{''}</option>
                            {tagList.data.map((tag: any, i: number) => (
                              <option key={i} value={tag.id}>
                                {tag.name}
                              </option>
                            ))}
                          </CSelect>
                        </CFormGroup>
                      </CCol>
                      <CCol xs="3">
                        <CFormGroup>
                          <CFormGroup>
                            <CButton
                              color="success"
                              className="my-4 mfs-auto"
                              onClick={handleAddCurator}>
                              Select User
                            </CButton>
                          </CFormGroup>
                        </CFormGroup>
                      </CCol>
                      <CCol xs="3">
                        <CFormGroup>
                          <CLabel>Date Range Start</CLabel>
                          <CInput
                            type="date"
                            id="date-input1"
                            name="start-date"
                            placeholder="date"
                            value={searchFields.startDate}
                            onChange={onChangeStartDate}
                          />
                        </CFormGroup>
                      </CCol>
                      <CCol xs="3">
                        <CFormGroup>
                          <CLabel>Date Range End</CLabel>
                          <CInput
                            type="date"
                            id="date-input2"
                            name="end-date"
                            placeholder="date"
                            value={searchFields.endDate}
                            onChange={onChangeEndDate}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CFormGroup>
                          <CLabel>Post Type</CLabel>
                          <ReactSelect
                            options={postTypes}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            onChange={(
                              newValue: OnChangeValue<any, any>,
                              actionMeta: ActionMeta<any>,
                            ) => {
                              onChangePostType(newValue);
                            }}
                          />
                        </CFormGroup>
                      </CCol>
                      <CCol
                        sm="3"
                        md="3"
                        className="mb-xl-0 mfs-auto mr-0"
                        style={{
                          display: 'flex',
                          height: 38,
                          alignSelf: 'center',
                          marginTop: 11,
                        }}>
                        <CButton
                          className="mfs-auto"
                          onClick={handleFilter}
                          color="danger">
                          Clear Search
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
          </CRow>
          <hr />

          <CRow>
            <CCol xl={12}>
              <CCard>
                <CCardHeader>
                  <CRow>
                    <CCol sm="4" md="2" className="mb-3 mb-xl-0 c-table-header">
                      Posts ({data.codeCount})
                    </CCol>
                  </CRow>
                </CCardHeader>

                <WSRDataTable
                  onClick={handleChange}
                  items={data.posts}
                  fields={fields}
                  scopedSlots={scopedSlots}
                  pageLimit={10}
                  page={page}
                  pageChange={pageChange}
                  itemsCount={data.codeCount}
                />

                <AddCurator
                  setCurationList={setCurationId}
                  setCuratorName={setCuratorName}
                  type={'detail'}
                  addCuratorModal={addCuratorModal}
                  setAddCuratorModal={setAddCuratorModal}
                />
              </CCard>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setPostModal(false);
              setModal(false);
            }}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>

      <WSRModal
        onShowModal={modal}
        setOnShowModal={setModal}
        modalTitle={'Update Operation'}
        modalBody={`Selected Post/Posts added successfully!`}
        operationButton={() => setModal(false)}
        operationButtonText={'Ok'}
      />
    </>
  );
};
