import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import moment from 'moment';

import {CCard, CCol, CRow, CImg} from '@coreui/react';
import {deletePosts as deletePostsAction} from '@actions/posts';
import {WSRModal} from '../../../../components';
import {WSRDataTable} from './WSRDataTable';

type Props = {
  page: number;
  pageLimit: number;
  pageChange: Function;
  postList: Array<any>;
};

export const MostPost: React.FC<Props> = (props) => {
  const {page, pageLimit, pageChange, postList} = props;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [deletePostId, setDeletePostId] = useState(-1);
  const [deletedWarningModal, setDeletedWarningModal] = useState(false);

  const dispatch = useDispatch();

  const handleDeletePost = () => {
    setModal(false);
    if (deletePostId !== -1) {
      dispatch(
        deletePostsAction.request(deletePostId, () => {
          setDeletedWarningModal(true);
        }),
      );
    }
  };

  const fields = [
    {
      key: 'id',
      label: 'Id',
      _style: {width: '4%'},
    },
    {
      key: 'image',
      label: 'Image',
      _style: {width: '5%'},
    },
    {
      key: 'title',
      label: 'Title',
      sorter: false,
      filter: false,
    },
    {
      key: 'type',
      label: 'Type',
      _style: {width: '10%'},
    },
    {
      key: 'publisher',
      label: 'Publisher',
      _style: {width: '10%'},
    },
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'user',
      label: 'User',
      _style: {width: '10%'},
    },
  ];

  const scopedSlots = {
    id: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.post.id}`)}>
        {item.post.id}
      </td>
    ),

    title: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.post.id}`)}>
        <div className="cut-text">{item.post.title}</div>
        <div className="small text-muted">
          Published: {moment(item.post.date).format('DD/MM/YYYY')}
        </div>
      </td>
    ),

    date: (item: any) => (
      <td>{moment(item.post.createdAt).format('DD/MM/YYYY')}</td>
    ),

    image: (item: any) => (
      <td>
        <div className="c-avatar">
          <CImg src={item.post.image} className="c-post-img" alt=" " />
        </div>
      </td>
    ),

    user: (item: any) => (
      <td>
        <div className="cut-text">{item.post.User.userName}</div>
      </td>
    ),

    publisher: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.post.id}`)}>
        {item.post.publisher}
      </td>
    ),

    type: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.post.id}`)}>
        {item.post.type}
      </td>
    ),
  };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <WSRDataTable
              items={postList}
              fields={fields}
              scopedSlots={scopedSlots}
              pageLimit={pageLimit}
              page={page}
              pageChange={pageChange}
              itemsCount={postList.length}></WSRDataTable>
          </CCard>
        </CCol>
      </CRow>

      <WSRModal
        onShowModal={modal}
        setOnShowModal={setModal}
        modalTitle={'Delete Post'}
        modalBody={`Are you sure you want to remove this category with id ${deletePostId}?`}
        operationButton={() => handleDeletePost()}
        operationButtonText={'Yes, remove it!'}
        cancelButton={() => {
          setModal(false);
          setDeletePostId(-1);
        }}
        cancelButtonText={'Cancel'}
      />

      <WSRModal
        onShowModal={deletedWarningModal}
        setOnShowModal={setDeletedWarningModal}
        modalTitle={'Delete Action'}
        modalBody={'Post has been deleted successfully!'}
        operationButton={() => setDeletedWarningModal(false)}
        operationButtonText={'OK'}
      />
    </>
  );
};
