import React, {useState} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {CCard, CCardBody, CCol, CRow} from '@coreui/react';
import moment from 'moment';

import {UserList} from '../UserList';

export const LastRegisteredUsers: React.FC = () => {
  const [registeredUsersPage, setRegisteredUsersPage] = useState(1);

  const registeredUserPageChange = (newPage: number) => {
    if (newPage == 0) newPage = 1;
    else {
      setRegisteredUsersPage(newPage);
    }
  };

  const {lastRegisteredUsers} = useSelector(
    (state) => ({
      lastRegisteredUsers: state.users.lastRegisteredUsers,
    }),
    shallowEqual,
  );

  return (
    <CCard>
      <CCardBody>
        <br />
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Last Registered Users
            </h5>
            <div className="small text-muted">
              {moment().format('DD/MM/YYYY')}
            </div>
          </CCol>
        </CRow>
        <br />
        <UserList
          page={registeredUsersPage}
          pageLimit={5}
          pageChange={registeredUserPageChange}
          userList={lastRegisteredUsers}
        />
      </CCardBody>
    </CCard>
  );
};
