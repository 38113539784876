import React, {useEffect} from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CImg,
} from '@coreui/react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {getUsersDetail as getUsersDetailAction} from '@actions/users';

import {UsersPostCommentList} from '../../components';

type Props = {match: any};

const UserDetailPage: React.FC<Props> = (props: Props) => {
  const {match} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const {user} = useSelector(
    (state) => ({
      user: state.users.usersDetail,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getUsersDetailAction.request(match.params.id));
  }, []);

  return (
    <>
      <CRow>
        <CCol sm="4" md="2" className="mb-3 mb-xl-0 mfs-auto">
          <CButton
            className="my-2 mfs-auto"
            block
            onClick={() => history.push(`/userUpdate/${match.params.id}`)}
            color="primary">
            Edit User
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm={12} lg={3}>
          <CCard>
            <CCardHeader className="user-profile-card">
              <div className="my-4">
                <img className="c-avatar-user" src={user.photo} alt=""></img>
              </div>
              <div className="text-center">
                <div className="text-uppercase font-weight-bold">
                  {user.fullName}
                  {user.premium === true &&
                  !(user.role === 'wiser' || user.role === 'publisher') ? (
                    <CImg
                      style={{marginLeft: 4, marginBottom: 3}}
                      src={'./../avatars/premium.svg'}
                      alt={'premium'}
                      width={10}
                      height={14}
                    />
                  ) : (
                    ''
                  )}
                  {user.role === 'wiser' || user.role === 'publisher' ? (
                    <CImg
                      style={{marginLeft: 5, marginBottom: 3}}
                      src={'./../avatars/prePremium.svg'}
                      alt={'wiserPremium'}
                      width={16}
                      height={16}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className="text-muted">{user.jobTitle}</div>
              </div>
            </CCardHeader>
            <CCardBody>
              <small className="text-muted font-weight-bold">Bio</small>
              <br />
              <small className="text-muted"> {user.bio}</small>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm={12} lg={9}>
          <CCard>
            <CCardHeader>
              <div className="text-muted font-weight-bold">Account Details</div>
            </CCardHeader>
            <CCardBody>
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          User Name &nbsp;
                        </strong>
                        {user.userName}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Website &nbsp;
                        </strong>
                        {user.website == null ? '-' : user.website}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Role &nbsp;
                        </strong>
                        {/*user.role*/}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Tags &nbsp;
                        </strong>
                        {/* {user.tags
                          ? user.tags.map((tag: any) => {
                              return tag.name + ',';
                            })
                          : ''} */}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Email &nbsp;
                        </strong>
                        {user.email}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Telephone &nbsp;
                        </strong>
                        {user.telephone == null ? '-' : user.telephone}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Registered Via &nbsp;
                        </strong>
                        {user.registrationMethod}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Invitation Code &nbsp;
                        </strong>
                        {user.InvitationCode == null
                          ? '-'
                          : user.InvitationCode.code}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Status &nbsp;
                        </strong>
                        {user.status}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Registered Date &nbsp;
                        </strong>
                        {moment(user.createdAt).format('DD/MM/YYYY')}
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <UsersPostCommentList
        userName={user.fullName}
        match={match.params.id}></UsersPostCommentList>
    </>
  );
};

export default UserDetailPage;
