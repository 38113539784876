import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getMostInteractedPosts as getMostInteractedPostsService} from '@services/posts';
import * as PostsActions from '@actions/posts';

export function* getMostInteractedPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(PostsActions.GET_MOST_INTERACTED_POSTS.REQUEST);
      const {data: response} = yield call(getMostInteractedPostsService);
      yield put(PostsActions.getMostInteractedPosts.success(response.data));
    } catch (error) {
      // Show info
      yield put(PostsActions.getMostInteractedPosts.failure(error));
    }
  }
}
