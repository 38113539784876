import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {
  getAllUserStats as getAllUserStatsAction,
  getLastRegisteredUsers as getLastRegisteredUsersAction,
  getLastActiveUsers as getLastActiveUsersAction,
} from '@actions/users';
import {
  getLastSharedPosts as getLastSharedPostsAction,
  getMostInteractedPosts as getMostInteractedPostsAction,
} from '@actions/posts';

import {
  UserStats,
  PostStats,
  LastSharedPosts,
  MostInteractedPosts,
  LastRegisteredUsers,
  LastActiveUsers,
  CurationListsStats,
} from './components';

const DashboardPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserStatsAction.request());
    dispatch(getLastSharedPostsAction.request());
    dispatch(getLastRegisteredUsersAction.request());
    dispatch(getLastActiveUsersAction.request());
    dispatch(getMostInteractedPostsAction.request());
  }, []);

  return (
    <>
      {/* TODO: chart example check it if it useful use it // ibocan@joinwiser.com ... */}
      {/* <MainChartExample style={{height: '300px', marginTop: '40px'}}/> */}
      <UserStats />
      <LastRegisteredUsers />
      <LastActiveUsers />
      <CurationListsStats />
      <PostStats />
      <LastSharedPosts />
      <MostInteractedPosts />
    </>
  );
};

export default DashboardPage;
