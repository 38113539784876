import {fork, all} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {example} from './example';

import {login, logout} from './auth';

import {getUser, checkUserName, createEditorialUser} from './user';

import {
  getAllUserStats,
  getLastRegisteredUsers,
  getLastActiveUsers,
  getAdmins,
  getAllUsers,
  getUsersDetail,
  updateManagementUsers,
  deleteManagementUsers,
  deleteUsers,
  updateUsers,
  usersRoleChange,
  usersStatusChange,
  getSelectableUsers,
  searchUsers,
  usersOnBoardingChange,
  getOnBoardingUsers,
  updateOnBoardingOrder,
  getWiserUsersByLocale,
  getPublisherUsersByLocale,
  updateWisers,
  updatePublishers,
  updateUserShowOnDiscovery,
  addUserToOrderList,
  removeUserFromOrderList,
  usersPremiumChange,
} from './users';

import {
  getAllPostStats,
  getLastSharedPosts,
  getMostInteractedPosts,
  getPosts,
  deletePosts,
  getPostDetail,
  updatePost,
  createPost,
  getCategoriesPosts,
  deleteCategoriesPosts,
  addCategoriesPosts,
  getPostComments,
  deletePostComment,
  searchPosts,
  getUncategorisedPosts,
  searchPostsForUser,
} from './posts';

import {
  getCurationsList,
  getCurationDetail,
  getCurationPosts,
  deleteCurationPosts,
  deleteCuration,
  updateCuration,
  addPostsToCuration,
  getCurationUsers,
  searchCurationUsers,
  createCuration,
  checkCurationCode,
  updateCurationListStatus,
  updateDiscoveryOrder,
  searchDiscoveryCurations,
  getCurationStats,
  getRandomCover,
  getWiserEditorialPicks,
  updateWiserEditorialPicks,
  getDynamicLink,
  getWeeklyUpdatedCurationList,
  updateStatusOfCurations,
} from './curation';

import {
  getCategories,
  deleteCategory,
  getCategoryDetail,
  updateCategory,
  createCategory,
  postMoveContent,
} from './categories';

import {getReferrers, searchReferrers} from './marketing';

import {
  getOnboardingCategories,
  deleteOnboardingCategory,
  addCategoryToOnboardingCategories,
  getOnboardingCategoryUsers,
  deleteOnboardingCategoryUsers,
  addUserToOnboardingCategory,
  getOnboardingUsers,
  updateOnboardingCategoryStatus,
} from './onboarding';

import {
  deleteCurationFromChooseMood,
  getChooseMoodCurations,
  postCurationToChooseMood,
  updateChooseMoodOrderList,
} from './chooseMood';

import {
  getAllLocales,
  getAllLanguages,
  getWeeklyUpdatedTime,
} from './management';

import {
  getTags,
  updateTag,
  deleteTag,
  createTag,
  removeCategoryFromTag,
} from './tags';

import {postBulkNotification} from './notifications';

import UncategorisedPosts from '@src/pages/UncategorisedPosts';

export default function* root(): Generator<Effect, void, any> {
  yield all([
    // example sagas...
    fork(example),

    // auth sagas...
    fork(login),
    fork(logout),

    // user sagas...
    fork(getUser),
    fork(checkUserName),
    fork(createEditorialUser),

    // users sagas...
    fork(getAllUserStats),
    fork(getLastRegisteredUsers),
    fork(getLastActiveUsers),
    fork(getAdmins),
    fork(getAllUsers),
    fork(getUsersDetail),
    fork(updateManagementUsers),
    fork(deleteManagementUsers),
    fork(deleteUsers),
    fork(updateUsers),
    fork(usersRoleChange),
    fork(usersStatusChange),
    fork(getSelectableUsers),
    fork(searchUsers),
    fork(usersOnBoardingChange),
    fork(getOnBoardingUsers),
    fork(updateOnBoardingOrder),
    fork(getWiserUsersByLocale),
    fork(getPublisherUsersByLocale),
    fork(updateWisers),
    fork(updatePublishers),
    fork(updateUserShowOnDiscovery),
    fork(addUserToOrderList),
    fork(removeUserFromOrderList),
    fork(usersPremiumChange),
    // posts sagas...
    fork(getAllPostStats),
    fork(getLastSharedPosts),
    fork(getMostInteractedPosts),
    fork(getPosts),
    fork(deletePosts),
    fork(getPostDetail),
    fork(updatePost),
    fork(createPost),
    fork(getCategoriesPosts),
    fork(deleteCategoriesPosts),
    fork(addCategoriesPosts),
    fork(getPostComments),
    fork(deletePostComment),
    fork(searchPosts),
    fork(getUncategorisedPosts),
    fork(searchPostsForUser),

    //curation sagas...
    fork(getCurationsList),
    fork(getWeeklyUpdatedCurationList),
    fork(getCurationDetail),
    fork(getCurationPosts),
    fork(deleteCurationPosts),
    fork(deleteCuration),
    fork(updateCuration),
    fork(addPostsToCuration),
    fork(getCurationUsers),
    fork(searchCurationUsers),
    fork(createCuration),
    fork(checkCurationCode),
    fork(updateCurationListStatus),
    fork(updateDiscoveryOrder),
    fork(searchDiscoveryCurations),
    fork(getCurationStats),
    fork(getRandomCover),
    fork(getWiserEditorialPicks),
    fork(updateWiserEditorialPicks),
    fork(getDynamicLink),
    fork(updateStatusOfCurations),

    //categories sagas...
    fork(getCategories),
    fork(deleteCategory),
    fork(getCategoryDetail),
    fork(updateCategory),
    fork(createCategory),
    fork(postMoveContent),

    //marketing sagas...
    fork(getReferrers),
    fork(searchReferrers),

    //onboarding sagas...
    fork(getOnboardingCategories),
    fork(deleteOnboardingCategory),
    fork(addCategoryToOnboardingCategories),
    fork(getOnboardingCategoryUsers),
    fork(deleteOnboardingCategoryUsers),
    fork(addUserToOnboardingCategory),
    fork(getOnboardingUsers),
    fork(updateOnboardingCategoryStatus),

    // management sagas...
    fork(getAllLocales),
    fork(getWeeklyUpdatedTime),
    fork(getAllLanguages),

    // chooseMood sagas...
    fork(deleteCurationFromChooseMood),
    fork(getChooseMoodCurations),
    fork(postCurationToChooseMood),
    fork(updateChooseMoodOrderList),

    //tag sagas...
    fork(getTags),
    fork(updateTag),
    fork(deleteTag),
    fork(createTag),
    fork(removeCategoryFromTag),

    //notification sagas...
    fork(postBulkNotification),
  ]);
}
