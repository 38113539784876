import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getLastRegisteredUsers as getLastRegisteredUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* getLastRegisteredUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(UsersActions.GET_LAST_REGISTERED_USERS.REQUEST);
      const {data: response} = yield call(getLastRegisteredUsersService);
      yield put(UsersActions.getLastRegisteredUsers.success(response.data));
    } catch (error) {
      // Show info
      yield put(UsersActions.getLastRegisteredUsers.failure(error));
    }
  }
}
