export const constants = {
  serviceTimeout: 10000,
  baseServiceUrl: 'https://staging-api.joinwiser.com/api/',
  AUTH_BASE: 'auth',
  USER_BASE: 'users',
  ADMIN_MANAGEMENT_BASE: 'management/admins',
  USER_MANAGEMENT_BASE: 'management/users',
  POSTS_BASE: 'posts',
  POST_MANAGEMENT_BASE: 'management/posts',
  WISER_BASE: 'wisers',
  COMMENTS_BASE: 'comments',
  CATEGORY_BASE: 'categories',
  TAG_BASE: 'tags',
  CATEGORY_MANAGEMENT_BASE: 'categories/management',
  CURATION_BASE: 'curationLists',
  LOCALE_BASE: 'locale',
  MANAGEMENT_BASE: 'management',
  STATS_BASE: 'stats',
  UTILS_BASE: 'utils',
  ONBOARDING_BASE: 'onboarding',
  LANGUAGE_BASE: 'language',
  NOTIFICATION_BASE: 'notifications',
  // Action constants...
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;
